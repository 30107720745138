<!-- Displays a number within a green circle -->
<template>
  <div
    class="tw-grid tw-h-7 tw-w-7 tw-shrink-0 tw-place-content-center tw-rounded-full tw-bg-dark-green tw-text-sm tw-font-medium tw-text-white"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NumberBullet",
}
</script>
