<template>
  <div
    class="tw-mb-4 tw-text-2xl tw-font-medium sm:tw-text-3xl md:tw-mb-8 lg:tw-text-4xl"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Header",
}
</script>
